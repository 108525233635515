'use client';

import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import { Params } from 'next/dist/shared/lib/router/utils/route-matcher';
import Cookies from 'js-cookie';

import { Consolidate_Order, DatalayerAnalytics } from 'services';
import { getOrderConfirmation } from '@cura/next-server/OrderConfirmationServerAction';

import { PageState } from '../components/PageState';
import { OrderConfirmation } from '../components/OrderConfirmation';
import { useSiteWideContext } from '../hooks/siteWideContext';
import { useDispensaryPathContext } from '../hooks/dispensaryPathContextProvider';

import { JaneDM } from '@iheartjane/dm-sdk';

export type OrderConfirmationProps = {
  searchParams: Params;
};

// INIT IHJ STUFF
export function getJdid() {
  const jdid = Cookies.get('jdid');

  if (jdid) {
    return jdid;
  }

  const newJdid = JaneDM.generateJaneDeviceId();
  Cookies.set('jdid', newJdid, {
    expires: 30
  });
  return newJdid;
}
const ihjClient = new JaneDM({
  apiKey: process.env.NEXT_PUBLIC_IHJ_API_KEY || '',
  endpoint: process.env.NEXT_PUBLIC_IHJ_ENDPOINT,
  identifier: { jdid: getJdid() }
});
// END IHJ STUFF

export const OrderConfirmationPage = ({
  searchParams
}: OrderConfirmationProps) => {
  const { currentPathDispensary, offerList } = useDispensaryPathContext();
  const { clearCart, user } = useSiteWideContext();
  const pathname = usePathname();
  const orderNumber = searchParams.orderNumber || null;

  const [orderLoading, setOrderLoading] = useState(true);
  const [pageViewFired, setPageViewFired] = useState(false);
  const [orderBeingFetched, setOrderBeingFetched] = useState(false);
  const [order, setOrder] = useState<Consolidate_Order | null>();
  const accountId = Cookies.get('curaleafAccountId');

  useEffect(() => {
    clearCart && clearCart();
    if (
      !orderBeingFetched &&
      ((!accountId && orderNumber && currentPathDispensary) ||
        (accountId && user && orderNumber && currentPathDispensary))
    ) {
      // added to dedupe requests (saw 4 locally)
      setOrderBeingFetched(true);
      getOrderConfirmation(
        currentPathDispensary,
        orderNumber,
        offerList,
        user?._id ? user : undefined
      )
        .then((res) => {
          setOrder(res);
          setOrderLoading(false);
        })
        .catch(() => {
          setOrderLoading(false);
        })
        .finally(() => {
          setOrderBeingFetched(false);
        });
    }
  }, [orderNumber, currentPathDispensary, user]);

  useEffect(() => {
    if (currentPathDispensary && !pageViewFired) {
      let pageType = 'shop/order-confirmation';
      if (pathname.includes('/android')) pageType += '/android';

      DatalayerAnalytics.pushPageView({
        page_type: pageType,
        consolidateDispensary: currentPathDispensary
      });
      setPageViewFired(true);
    }
  }, [pathname, currentPathDispensary]);

  useEffect(() => {
    if (order) {
      if (!order.viewed && currentPathDispensary) {
        const items = order.items.map((item) => {
          const variant = item.product.variants.find(
            (v) => v.option === item.option
          )!; // Product should always have a variant that matches!
          return {
            item_id: variant.id,
            item_name: item.product.name,
            discount: DatalayerAnalytics.calculateDiscount(variant),
            item_brand: item.product.brand?.name,
            item_category: item.product.category,
            item_category2: item.product.subcategory || undefined,
            item_variant: variant.option,
            affiliation: currentPathDispensary.friendlyName,
            price: variant.isSpecial
              ? variant.specialPrice || undefined
              : variant.price || undefined,
            quantity: item.quantity,
            posId: (item.product as any).posMetaData?.id // TODO fix type? is buried in pick statements x.x
          };
        });
        DatalayerAnalytics.pushPurchase({
          value: order.total,
          transaction_id: order.orderNumber,
          tax: order.tax,
          subtotal: order.subtotal,
          order_discount: order.discounts,
          fee: order.fees,
          items,
          consolidateDispensary: currentPathDispensary
        });

        // IHJ STUFF
        if (currentPathDispensary.sponsoredIHJStoreId) {
          ihjClient.sendPosCheckout({
            posOrderId: order.orderNumber + '',
            posUserId: '',
            products: items.map((item) => {
              return {
                posProductId: item.posId,
                price: item.price || 0,
                quantity: item.quantity
              };
            }),
            storeId: currentPathDispensary.sponsoredIHJStoreId
          });
        }
        // END IHJ STUFF

        if (typeof window !== 'undefined' && window.optimizely) {
          window.optimizely = window.optimizely || [];
          window.optimizely.push({
            type: 'event',
            eventName: 'trackRevenue',
            tags: {
              revenue: order.total * 100
            }
          });
        }
      }
    }
  }, [order]);

  if (!orderLoading && !order) {
    DatalayerAnalytics.pushErrorEvent({
      category: 'expected',
      location: 'orderConfirmationPage',
      description: 'Order Not Found',
      consolidateDispensary: currentPathDispensary
    });
    return (
      <PageState
        header="Order not found."
        link={{
          url: currentPathDispensary.shopLink,
          text: 'Start shopping!'
        }}
      />
    );
  }

  return (
    <>
      {orderLoading ? (
        <OrderConfirmation isLoading={true} />
      ) : !orderLoading && order ? (
        <OrderConfirmation
          isLoading={false}
          order={order}
          orderNumber={orderNumber}
        />
      ) : null}
    </>
  );
};
