import { CTAButton } from '../../CTAButton';
import { useLoyaltyData } from '../../../hooks/graphs';
import { useSiteWideContext } from '../../../hooks/siteWideContext';
import { logout } from '../../../utils/account';
import { DatalayerAnalytics } from 'services';

export type UserPopupType = {
  options: {
    closeNav: () => void;
    showTooltip: boolean;
    toggleAccountDrawer: (mode: 'login' | 'create') => void;
  };
  type?: 'user';
};

export const UserPopup = ({ options }: UserPopupType): JSX.Element | null => {
  const { clearCart, clearUser, isLoggedIn, user } = useSiteWideContext();

  const { loyaltyMemberInfo } = useLoyaltyData(isLoggedIn);

  let title = 'Sign In for the Best Experience';
  let buttonText = 'Sign In';

  if (isLoggedIn && user) {
    title = `Welcome Back, ${user.firstName}`;
    buttonText = 'My Account';
  }

  const handleLogout = () => {
    logout(clearUser, clearCart);
    DatalayerAnalytics.pushAccountEvent({
      action: 'logout'
    });
    options.closeNav();
  };

  return (
    <>
      <div className="bg-white d-flex align-items-center justify-content-center">
        <h5 className="body-m margin-bottom-0">{title}</h5>
      </div>
      {isLoggedIn && loyaltyMemberInfo?.totalPointsAvailable ? (
        <div className="points body-l font-bold text-black">
          {loyaltyMemberInfo?.totalPointsAvailable} Points
        </div>
      ) : null}
      <div>
        <CTAButton
          size="md"
          variant="primary"
          fullWidth={true}
          callBackFunc={() => options.toggleAccountDrawer('login')}
          text={buttonText}
        />
        {!isLoggedIn ? (
          <div className="margin-top-8">
            <span className="body-sm margin-right-5 pointer">
              New Customer?
            </span>
            <a
              tabIndex={0}
              className="body-sm text-primary underline pointer"
              onClick={() => options.toggleAccountDrawer('create')}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  options.toggleAccountDrawer('create');
                }
              }}>
              Register
            </a>
          </div>
        ) : (
          <div className="margin-top-8">
            <a
              tabIndex={0}
              className="body-sm text-primary underline pointer"
              onClick={handleLogout}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleLogout();
                }
              }}>
              Sign Out
            </a>
          </div>
        )}
      </div>
    </>
  );
};
