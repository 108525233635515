import { useState } from 'react';
import { FormikProps } from 'formik';
import classNames from 'classnames';
import { Dropdown } from 'primereact/dropdown';
import { FloatLabel } from 'primereact/floatlabel';

import {
  DefaultFormik,
  getFormErrorMessage,
  isFormFieldValid
} from '../../../../utils/forms';

import '../form.scss';

export type DropdownProps = {
  title: string;
  dropdown_options: string[];
  sort?: boolean;
  field: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik?: FormikProps<any>;
  required?: boolean;
  disabled?: boolean;
};

export const DropdownSelection = ({
  dropdown_options,
  field,
  formik,
  title,
  sort = false,
  required,
  disabled
}: DropdownProps) => {
  const [open, setOpen] = useState(false);

  if (!formik) formik = DefaultFormik();

  if (!dropdown_options || !dropdown_options.length) return null;

  if (sort) {
    dropdown_options.sort((a, b) => {
      if (a > b) return 1;
      if (a < b) return -1;
      return 0;
    });
  }

  return (
    <div
      className={classNames(
        'dropdown-curaleaf relative margin-bottom-16 d-flex flex-column justify-content-end',
        {
          'form-error':
            isFormFieldValid(field, formik) && formik.submitCount > 0
        }
      )}>
      {required ? (
        <span className="body-sm text-secondary-dark d-flex justify-content-end padding-bottom-3">
          Required
        </span>
      ) : null}
      <FloatLabel>
        <Dropdown
          data-test="dropdown"
          name={field}
          inputId={field}
          value={formik.values[field]}
          options={dropdown_options}
          scrollHeight={
            dropdown_options.length <= 4
              ? `${50 * dropdown_options.length + 25}px`
              : ' 225px'
          }
          onChange={formik.handleChange}
          onShow={() => setOpen(true)}
          onHide={() => setOpen(false)}
          dropdownIcon={open ? 'pi pi-chevron-up' : 'pi pi-chevron-down'}
          disabled={disabled}
        />
        <label htmlFor={field}>{title}</label>
      </FloatLabel>
      {formik.submitCount > 0 ? getFormErrorMessage(field, formik) : null}
    </div>
  );
};
