import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import request from 'graphql-request';
import Cookies from 'js-cookie';

import { usePathname } from 'next/navigation';
import { Dispatch, useEffect } from 'react';
import moment from 'moment';

import {
  checkoutFragment,
  Consolidate_Cart_Item_V2,
  Consolidate_ProductVariant,
  Curaql,
  Curaql_OrderType,
  DatalayerAnalytics,
  DutchiePlus_Generated_Checkout,
  DutchiePlus_OrderType
} from 'services';
import { SiteWideWrapperProps } from './siteWideContext';
import { snackbarMessage } from '../utils/snackbar';
import { dutchieCartToConsolidateCartMap } from './siteWideContext/helpers';
import { checkoutMetaMap } from '../utils/checkoutMetaMap';
import { useStorage } from './useStorage';
import { ProductListTilePopupProps } from '../components/ProductListTile';

type DutchieCartDataProps = Pick<
  SiteWideWrapperProps,
  | 'selectedDispensary'
  | 'selectedDispensaryID'
  | 'selectedDispensaryLoading'
  | 'userMenuType'
  | 'user'
> & {
  setPopupProduct: SiteWideWrapperProps['popup']['setProduct'];
  setShowPopup: SiteWideWrapperProps['popup']['setShow'];
  updateUserOrderTypeLocalStorage: Dispatch<Curaql_OrderType>;
};

export type DutchieAddToCartProps = {
  checkoutId?: string;
  dispensaryUniqueId: string;
  product: ProductListTilePopupProps['item']['product'];
  quantity: number;
  retailerId: string;
  variant: Consolidate_ProductVariant;
  analyticsContext: {
    pathname: string;
    categoryKey?: string[] | string;
    index?: number;
  };
  cb: () => void;
  ignoreErrors?: boolean;
};

export type DutchieRemoveFromCartProps = {
  cartItem: Consolidate_Cart_Item_V2;
  cb: () => void;
  dispensaryUniqueId: string;
  retailerId: string;
};

type CartMeta = {
  checkoutId: string;
  dispensaryUniqueId: string;
  isUserCart: false;
  itemQuantity: number;
  items:
    | DutchiePlus_Generated_Checkout['items']
    | Consolidate_Cart_Item_V2[] /* used on login to update cart meta data based on updatedAt and items.length */;
  menuType: 'RECREATIONAL' | 'MEDICAL';
  updatedAt: string;
};

export type UpdateUserDBCartProps = {
  checkoutId: string;
  dispensaryUniqueId: string;
  pricingType: 'RECREATIONAL' | 'MEDICAL';
  redirectUrl: string;
  onSuccessCallback?: () => void;
};

export type UpdateUserDBCartData = { updateUserDBCart: { success: true } };

export type DutchieUpdateOrderTypeProps = {
  orderType: DutchiePlus_OrderType;
  dispensaryUniqueId: string;
};

export type RemoveItemResponse = { removeItem: DutchiePlus_Generated_Checkout };

type UpdateCheckoutResponse = {
  updateCheckout: DutchiePlus_Generated_Checkout;
};

type CheckoutResponse = { checkout: DutchiePlus_Generated_Checkout };

export const useDutchieCartData = ({
  selectedDispensary,
  selectedDispensaryLoading,
  setPopupProduct,
  setShowPopup,
  userMenuType,
  updateUserOrderTypeLocalStorage,
  user
}: DutchieCartDataProps) => {
  const baseUrl = process.env.DUTCHIE_PLUS_URL as string;
  const requestHeaders = {
    authorization: `Bearer public-${process.env.DUTCHIE_PLUS_PUBLIC_TOKEN}`
  };
  const pathname = usePathname();
  const queryClient = useQueryClient();
  const [emails] = useStorage<string[]>('employeeEmails', []);

  const defaultMenuType =
    Array.isArray(selectedDispensary?.menuTypes) &&
    selectedDispensary.menuTypes.includes(userMenuType)
      ? userMenuType
      : selectedDispensary?.menuTypes[0];
  let cartMeta = Cookies.get(Curaql.CART_META_KEY);
  const parsedCartMeta: CartMeta | null = cartMeta
    ? JSON.parse(cartMeta)
    : null;
  let metaCheckoutId =
    parsedCartMeta && parsedCartMeta.checkoutId
      ? parsedCartMeta.checkoutId
      : '';
  const menuType =
    parsedCartMeta && parsedCartMeta.menuType
      ? parsedCartMeta.menuType
      : defaultMenuType;

  const {
    data: cart,
    error,
    isFetching,
    isLoading,
    refetch
  } = useQuery({
    queryKey: ['dutchie_cart'],
    queryFn: async () => {
      const currCartMeta = Cookies.get(Curaql.CART_META_KEY);
      const parsedCurrCartMeta: CartMeta | null = currCartMeta
        ? JSON.parse(currCartMeta)
        : null;

      if (
        !selectedDispensary?.retailerId ||
        !metaCheckoutId ||
        !parsedCurrCartMeta?.checkoutId
      ) {
        return null;
      }

      if (selectedDispensary?.uid === parsedCurrCartMeta?.dispensaryUniqueId) {
        try {
          const checkoutData = await request<CheckoutResponse>(
            baseUrl,
            `query Checkout(
              $retailerId: ID!
              $checkoutId: ID!
            ) {
              checkout(
                retailerId: $retailerId
                id: $checkoutId
              ) {
                ${checkoutFragment}
              }
            }`,
            {
              checkoutId: metaCheckoutId,
              retailerId: selectedDispensary.retailerId || ''
            },
            requestHeaders
          );

          if (!checkoutData?.checkout) {
            console.log('No checkout data, clearing cart');
            clearCart();
            return null;
          }

          if (
            parsedCurrCartMeta.dispensaryUniqueId !== selectedDispensary.uid
          ) {
            console.log('Cart dispensary mismatch, clearing cart');
            clearCart();
            return null;
          }

          return dutchieCartToConsolidateCartMap(
            checkoutData.checkout,
            selectedDispensary.uid
          );
        } catch (error: unknown) {
          handleGraphQLError(error, 'query dutchie_cart', selectedDispensary);
          clearCart();
          return null;
        }
      } else {
        clearCart();
      }
      return null;
    },
    enabled: !!cartMeta && !!selectedDispensary
  });

  const clearCart = async (cb?: () => void) => {
    try {
      // Clear all cart-related cookies
      Cookies.remove(Curaql.CART_META_KEY);

      // Reset all cart-related state
      cartMeta = undefined;
      metaCheckoutId = '';

      // Cancel any in-flight cart queries
      await queryClient.cancelQueries({ queryKey: ['dutchie_cart'] });

      // Reset the cart query cache and set it to null to trigger a re-render
      queryClient.setQueryData(['dutchie_cart'], null);

      // Also reset the query to ensure a fresh fetch if needed
      queryClient.resetQueries({
        queryKey: ['dutchie_cart'],
        exact: true,
        type: 'active'
      });

      // Execute callback if provided
      if (typeof cb === 'function') {
        cb();
      }
    } catch (error) {
      console.error('Error clearing cart:', error);
      // Still try to execute callback even if there's an error
      if (typeof cb === 'function') {
        cb();
      }
    }
  };

  useEffect(() => {
    // clear cart if cart's dispensary doesn't match the selected dispensary
    if (
      cart &&
      !selectedDispensaryLoading &&
      selectedDispensary &&
      (cart.dispensaryUniqueId !== selectedDispensary.uid ||
        pathname.includes('order-confirmation'))
    ) {
      clearCart();
    }
  }, [
    pathname,
    cart,
    userMenuType,
    selectedDispensary?.uid,
    selectedDispensaryLoading,
    clearCart
  ]);

  const addItemToCart = (variables: DutchieAddToCartProps) => {
    // Check for dispensary mismatch first
    const currCartMeta = Cookies.get(Curaql.CART_META_KEY);
    const parsedCurrCartMeta: CartMeta | null = currCartMeta
      ? JSON.parse(currCartMeta)
      : null;

    if (
      parsedCurrCartMeta &&
      parsedCurrCartMeta.dispensaryUniqueId !== selectedDispensary?.uid
    ) {
      console.log('Cart dispensary mismatch, clearing cart before add');
      clearCart();
    }

    if (cart) {
      const matchingCartItem = cart.items.find(
        (item) => item.product.id === variables.product.id
      );
      if (
        matchingCartItem &&
        variables.variant.quantity &&
        variables.variant.quantity <
          matchingCartItem.quantity + variables.quantity
      ) {
        variables.cb();
        return snackbarMessage('Added quantity exceeds product inventory.');
      }
    }
    if (metaCheckoutId) {
      return addItems.mutateAsync(variables);
    } else {
      return createCheckout.mutateAsync(variables);
    }
  };

  type AddItemResponse = { addItem: DutchiePlus_Generated_Checkout };

  type CreateCheckoutResponse = {
    createCheckout: DutchiePlus_Generated_Checkout;
  };

  const addItems = useMutation<AddItemResponse, Error, DutchieAddToCartProps>({
    mutationFn: async (variables: DutchieAddToCartProps) => {
      return request<AddItemResponse>(
        baseUrl,
        `mutation AddItemToCheckout(
          $checkoutId: ID!
          $option: String!
          $productId: ID!
          $quantity: Int!
          $retailerId: ID!
        ) {
          addItem(
            checkoutId: $checkoutId
            option: $option
            productId: $productId
            quantity: $quantity
            retailerId: $retailerId
          ) {
            ${checkoutFragment}
          }
        }`,
        {
          checkoutId: variables.checkoutId || metaCheckoutId,
          option: variables.variant.option,
          productId: variables.product.id,
          quantity: variables.quantity,
          retailerId: variables.retailerId
        },
        requestHeaders
      );
    },
    onSuccess: (data: AddItemResponse, variables: DutchieAddToCartProps) => {
      const newCartData: DutchiePlus_Generated_Checkout = data.addItem;
      queryClient.setQueryData(
        ['dutchie_cart'],
        dutchieCartToConsolidateCartMap(
          newCartData,
          variables.dispensaryUniqueId
        )
      );
      /* Cart Popup - should not show on cart page */
      if (
        newCartData.items[0] &&
        !variables.analyticsContext.pathname.includes('cart') &&
        !variables.ignoreErrors
      ) {
        setPopupProduct({
          product: variables.product,
          variant: variables.variant,
          quantity: variables.quantity
        });
        setShowPopup(true);
      }
      variables.cb();

      /* Update Cart Meta */
      let newQuantity = 0;
      newCartData.items.map((item) => {
        newQuantity += item.quantity;
      });

      const newCookies: CartMeta = {
        checkoutId: newCartData.id,
        dispensaryUniqueId: variables.dispensaryUniqueId,
        isUserCart: false,
        itemQuantity: newQuantity,
        items: newCartData.items,
        menuType: newCartData.pricingType,
        updatedAt: moment(newCartData.updatedAt).format('x')
      };
      Cookies.set(Curaql.CART_META_KEY, JSON.stringify(newCookies));

      /* Analytics */
      if (!variables.ignoreErrors) {
        const generatedAnalyticsContext =
          DatalayerAnalytics.generateAnalyticsContext(
            variables.analyticsContext.pathname,
            selectedDispensary?.shopLink || '',
            '',
            '',
            { key: `${variables.analyticsContext.categoryKey}` },
            variables.analyticsContext.index
          );

        const mappedAnalyticsContext = {
          context: {
            item_variant: variables.variant,
            item_list_id: generatedAnalyticsContext.list.id,
            item_list_name: generatedAnalyticsContext.list.name,
            index: variables.analyticsContext.index
          },
          consolidateDispensary: selectedDispensary,
          product: variables.product,
          quantity: variables.quantity // only the amount added to cart per event, not the total
        };

        if (variables.quantity > 0) {
          DatalayerAnalytics.pushAddToCart(mappedAnalyticsContext);
        } else {
          DatalayerAnalytics.pushRemoveFromCart(mappedAnalyticsContext);
        }
      }
    },
    onError: (error: Error, variables: DutchieAddToCartProps) => {
      variables.cb();
      try {
        if (!variables.ignoreErrors) {
          let errorMessage = error.message;
          if (errorMessage?.includes(':')) {
            const errorMessageParts = errorMessage.split(':');
            errorMessage = errorMessageParts[0]
              ? errorMessageParts[0]
              : errorMessage;
          }
          console.error('addItems', errorMessage);

          let userFacingError = 'Something went wrong, please try again later.';
          // Usual allotment error
          if (errorMessage?.includes('purchase limit')) {
            userFacingError = errorMessage.replace('Error: ', '');
            // to catch the unprocessable entity error
          } else if (errorMessage?.includes('Validation errors')) {
            userFacingError =
              "We've run out of stock for this product. Please try again later.";
          }

          snackbarMessage(userFacingError, 'error');
          DatalayerAnalytics.pushErrorEvent({
            category: errorMessage.includes('purchase limit')
              ? 'expected'
              : 'api',
            location: 'addToCart',
            description: errorMessage,
            consolidateDispensary: selectedDispensary || undefined
          });
        }
      } catch (error) {
        snackbarMessage('Something went wrong, please try again later.', 'error');
      }
    }
  });

  const createCheckout = useMutation<
    CreateCheckoutResponse,
    Error,
    DutchieAddToCartProps
  >({
    mutationFn: async (variables: DutchieAddToCartProps) => {
      return request<CreateCheckoutResponse>(
        baseUrl,
        `mutation CreateCheckout(
          $address: CheckoutAddressInput
          $metadata: JSON
          $orderType: OrderType!
          $pricingType: PricingType!
          $retailerId: ID!
        ) {
          createCheckout(
            address: $address
            metadata: $metadata
            orderType: $orderType
            pricingType: $pricingType
            retailerId: $retailerId
          ) {
            ${checkoutFragment}
          }
        }`,
        {
          metadata: checkoutMetaMap(user, emails),
          orderType: process.env.IS_KIOSK === 'true' ? 'KIOSK' : 'PICKUP',
          pricingType: menuType,
          retailerId: selectedDispensary?.retailerId || ''
        },
        requestHeaders
      );
    },
    onSuccess: (
      data: CreateCheckoutResponse,
      variables: DutchieAddToCartProps
    ) => {
      const newCartData: DutchiePlus_Generated_Checkout = data.createCheckout;
      if (newCartData) {
        const newCookies: CartMeta = {
          checkoutId: newCartData.id,
          dispensaryUniqueId: variables.dispensaryUniqueId,
          isUserCart: false,
          itemQuantity: 0,
          items: newCartData.items,
          menuType: newCartData.pricingType,
          updatedAt: moment(newCartData.updatedAt).format('x')
        };
        Cookies.set(Curaql.CART_META_KEY, JSON.stringify(newCookies));
        return addItems.mutateAsync({
          ...variables,
          checkoutId: newCartData.id
        });
      }
    },
    onError: (error: Error, variables: DutchieAddToCartProps) => {
      console.error('createCheckout', error);
      variables.cb();
      //clear cart meta and cache
      const message = error.message;
      snackbarMessage(
        message && (message.includes('Failed') || message.includes('Sorry'))
          ? message.replace('Error: ', '')
          : 'Something went wrong, please try again.',
        'error'
      );
      DatalayerAnalytics.pushErrorEvent({
        category: 'api',
        location: 'createCheckout',
        description: message,
        consolidateDispensary: selectedDispensary || undefined
      });
    }
  });

  const removeItemFromCart = useMutation<
    RemoveItemResponse,
    Error,
    DutchieRemoveFromCartProps
  >({
    mutationFn: async (variables) => {
      try {
        const response = await request<RemoveItemResponse>(
          baseUrl,
          `mutation RemoveItemFromCheckout( 
            $checkoutId: ID!
            $itemId: ID!
            $retailerId: ID!
          ) {
            removeItem(
              checkoutId: $checkoutId
              itemId: $itemId
              retailerId: $retailerId
            ) {
              ${checkoutFragment}
            }
          }`,
          {
            checkoutId: metaCheckoutId,
            itemId: variables.cartItem.id,
            retailerId: variables.retailerId
          },
          requestHeaders
        );
        if (!response.removeItem) {
          throw new Error('Failed to remove item from cart');
        }
        variables.cb();
        return response;
      } catch (error) {
        handleGraphQLError(error, 'removeItemFromCart', selectedDispensary);
        throw error;
      }
    },
    onSuccess: (
      data: RemoveItemResponse,
      variables: DutchieRemoveFromCartProps
    ) => {
      const newCartData: DutchiePlus_Generated_Checkout = data.removeItem;

      // Update cart data in query client
      queryClient.setQueryData(
        ['dutchie_cart'],
        dutchieCartToConsolidateCartMap(
          newCartData,
          variables.dispensaryUniqueId
        )
      );

      // Update cart meta
      let newQuantity = 0;
      newCartData.items.map((item) => {
        newQuantity += item.quantity;
      });

      const newCookies: CartMeta = {
        checkoutId: newCartData.id,
        dispensaryUniqueId: variables.dispensaryUniqueId,
        isUserCart: false,
        itemQuantity: newQuantity,
        items: newCartData.items,
        menuType: newCartData.pricingType,
        updatedAt: moment(newCartData.updatedAt).format('x')
      };
      Cookies.set(Curaql.CART_META_KEY, JSON.stringify(newCookies));

      // Analytics
      const selectedVariant =
        variables.cartItem.product.variants.length > 1
          ? variables.cartItem.product.variants.find(
              (v) => v.option === variables.cartItem.option
            )
          : variables.cartItem.product.variants[0];
      if (selectedVariant) {
        DatalayerAnalytics.pushRemoveFromCart({
          product: variables.cartItem.product,
          context: {
            item_variant: selectedVariant,
            item_list_id: undefined,
            item_list_name: undefined,
            index: undefined
          },
          consolidateDispensary: selectedDispensary,
          quantity: variables.cartItem.quantity
        });
      }
    }
  });

  const updateCartOrderType = useMutation<
    UpdateCheckoutResponse,
    Error,
    DutchieUpdateOrderTypeProps
  >({
    mutationFn: async (variables: DutchieUpdateOrderTypeProps) => {
      return request<UpdateCheckoutResponse>(
        baseUrl,
        `mutation UpdateCheckout(
          $retailerId: ID!
          $checkoutId: ID!
          $orderType: OrderType!
          $pricingType: PricingType!
          $metadata: JSON
        ) {
          updateCheckout(
            checkoutId: $checkoutId
            retailerId: $retailerId
            orderType: $orderType
            pricingType: $pricingType, 
            metadata: $metadata) {
              ${checkoutFragment}
          }
        }`,
        {
          checkoutId: metaCheckoutId,
          retailerId: selectedDispensary?.retailerId || '',
          orderType: variables.orderType,
          pricingType: userMenuType,
          metadata: checkoutMetaMap(user)
        },
        requestHeaders
      );
    },
    onSuccess: (
      data: UpdateCheckoutResponse,
      variables: DutchieUpdateOrderTypeProps
    ) => {
      updateUserOrderTypeLocalStorage(variables.orderType);
      const newCartData: DutchiePlus_Generated_Checkout = data.updateCheckout;
      queryClient.setQueryData(
        ['dutchie_cart'],
        dutchieCartToConsolidateCartMap(
          newCartData,
          variables.dispensaryUniqueId
        )
      );
    },
    onError: (error: Error) => {
      handleGraphQLError(error, 'updateOrderType', selectedDispensary);
    }
  });

  return {
    addItemToCart,
    cart: { data: cart, error, loading: isLoading && isFetching, refetch },
    clearCart,
    removeItemFromCart,
    updateCartOrderType
  };
};

const handleGraphQLError = (
  error: unknown,
  location: string,
  selectedDispensary?: any
) => {
  const errorMessage =
    error instanceof Error
      ? error.message
      : typeof error === 'object' && error !== null && 'response' in error
        ? (error as any).response?.data?.errors?.[0]?.message || String(error)
        : String(error);

  console.error(location, errorMessage);
  snackbarMessage(
    errorMessage &&
      (errorMessage.includes('Failed') || errorMessage.includes('Sorry'))
      ? errorMessage.replace('Error: ', '')
      : 'Something went wrong, please try again.',
    'error'
  );
  DatalayerAnalytics.pushErrorEvent({
    category: errorMessage.includes('purchase limit') ? 'expected' : 'api',
    location,
    description: errorMessage,
    consolidateDispensary: selectedDispensary || undefined
  });
};

const updateCartMeta = (
  newCartData: DutchiePlus_Generated_Checkout,
  dispensaryUniqueId: string,
  menuType: 'RECREATIONAL' | 'MEDICAL'
) => {
  let newQuantity = 0;
  newCartData.items.map((item) => {
    newQuantity += item.quantity;
  });

  const newCookies: CartMeta = {
    checkoutId: newCartData.id,
    dispensaryUniqueId,
    isUserCart: false,
    itemQuantity: newQuantity,
    items: newCartData.items,
    menuType,
    updatedAt: moment(newCartData.updatedAt).format('x')
  };
  Cookies.set(Curaql.CART_META_KEY, JSON.stringify(newCookies));
};
