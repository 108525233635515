'use client';

import { createContext, ReactNode, useContext, useEffect, useRef } from 'react';

import {
  Consolidate_Dispensary,
  CurrentPathDispensary,
  DutchiePlus_PricingType,
  getMinHeightClass,
  InformationBannerType
} from 'services';
import { AlertProps } from '../components/Alert';
import { FeedbackTab } from '../components/FeedbackTab';
import { useParams, usePathname } from 'next/navigation';
import { CategoryLink, useSiteWideContext } from './siteWideContext';
import { SpecialTileProps } from '../components/StorefrontCarousel/components/SpecialTile';
import { MailingOptInBanner } from '../components/MailingOptIn/Banner';
import { SpecialsTray } from '../components/SpecialsTray';
import { FavoredBrands } from '../templates/storefrontPage';
import { CategoryMenu } from '../components/HeaderNavigation/CategoryMenu';

import './dispensary-path.scss';

export interface DispensaryPathWrapperProps {
  alerts: (AlertProps & { id: number })[];
  staticCategories: {
    RECREATIONAL: CategoryLink[];
    MEDICAL: CategoryLink[];
  };
  currentPathDispensary: CurrentPathDispensary;
  defaultMenuType: DutchiePlus_PricingType;
  favoredBrands: FavoredBrands[];
  offerList: SpecialTileProps[];
  todaysOffers: InformationBannerType | null;
}

// Create Context Object
export const DispensaryPathContext = createContext<DispensaryPathWrapperProps>(
  {} as DispensaryPathWrapperProps
);

export type DispensaryPathContextProviderProps = {
  children: ReactNode;
  // Props that need to be passed in
  contextProps: Omit<DispensaryPathWrapperProps, 'defaultMenuType'>;
};

// Context for current shop path dispensary
export const DispensaryPathContextProvider = ({
  children,
  contextProps
}: DispensaryPathContextProviderProps) => {
  const {
    cart,
    isMobile,
    selectedDispensary,
    selectedDispensaryLoading,
    setSelectedDispensary,
    userMenuType
  } = useSiteWideContext();

  useEffect(() => {
    // update selected dispensary to current path dispensary
    if (
      (!selectedDispensaryLoading && !selectedDispensary) ||
      (selectedDispensary &&
        selectedDispensary.slug !== contextProps.currentPathDispensary.slug)
    ) {
      setSelectedDispensary(contextProps.currentPathDispensary.uid);
      cart.refetch();
    }
  }, [
    contextProps.currentPathDispensary.slug,
    selectedDispensary,
    selectedDispensaryLoading
  ]);

  /* ------ FEEDBACK ------ */
  const feedbackUrl =
    contextProps.currentPathDispensary.storefrontMap?.feedbackUrl || '';

  /* ------------------------------------------------------ */

  const context = {
    ...contextProps,
    // DEFAULTS MENUTYPE FROM USER MENUTYPE > RECREATIONAL > FIRST TYPE IN ARRAY. This way a user can still see products even if the dispensary doesn't have their menutype.
    defaultMenuType: contextProps.currentPathDispensary.menuTypes.includes(
      userMenuType
    )
      ? userMenuType
      : (contextProps.currentPathDispensary
          .menuTypes[0] as Consolidate_Dispensary['menuTypes'][number])
  };

  const params = useParams();
  const isKiosk = process.env.IS_KIOSK === 'true';

  const minHeightClass = getMinHeightClass(
    'ecom',
    isKiosk,
    process.env.CONTENTSTACK_ENVIRONMENT
  );

  const pathname = usePathname();
  const path = pathname.split('/').join('/');
  const isLegacyPath =
    path.includes('categories2') ||
    path.includes('brands2') ||
    path.includes('products2') ||
    path.includes('specials2');

  // Dynamically sizes the height for category menu sidebar
  const dispensaryPathLayoutRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (contextProps.staticCategories[context.defaultMenuType].length) {
      const menu = document.getElementById('category-menu-sidebar');
      if (menu) {
        const height = menu.offsetHeight;
        if (height > 0 && dispensaryPathLayoutRef.current) {
          dispensaryPathLayoutRef.current.style.minHeight = `${height}px`;
        }
      }
    }
  }, [contextProps.staticCategories]);

  const isOrderConfirmationPage =
    pathname.split('/')[4] === 'order-confirmation';
  const hideCategoryMenu = isMobile || isLegacyPath || isOrderConfirmationPage;

  return (
    <DispensaryPathContext.Provider value={context}>
      <div
        ref={dispensaryPathLayoutRef}
        className="relative dispensary-path-layout">
        {hideCategoryMenu ? null : (
          <CategoryMenu
            categories={
              contextProps.staticCategories[context.defaultMenuType] ?? []
            }
            shopLink={contextProps.currentPathDispensary.shopLink}
            currentPathDispensary={contextProps.currentPathDispensary}
          />
        )}
        <div className={isLegacyPath || isOrderConfirmationPage ? '' : 'main'}>
          <div className={minHeightClass}>
            <FeedbackTab url={feedbackUrl} />
            {children}
          </div>
        </div>
      </div>
      <MailingOptInBanner />
      {/* Hiding special trays on PDP mobile for both web & kiosk */}
      {params.productId && isMobile ? null : (
        <SpecialsTray
          selectedDispensary={contextProps.currentPathDispensary}
          offerList={contextProps.offerList}
          todaysOffers={contextProps.todaysOffers}
        />
      )}
    </DispensaryPathContext.Provider>
  );
};

// Use this in child components
export const useDispensaryPathContext = () => useContext(DispensaryPathContext);
