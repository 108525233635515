import Link from 'next/link';
import Image from 'next/image';
import { useState } from 'react';
import { usePathname } from 'next/navigation';

import { ProductListTileProps, ProductListTileOrderProps } from '.';
import { ecommerceSanitizeQuery } from '../../utils/url';
import { useSiteWideContext } from '../../hooks/siteWideContext';

import { PriceSection } from '../PriceSection';
import { QuantitySelect } from '../QuantitySelect';
import { StrainTag } from '../ProductTile/StrainTag';

import ProductDefaultImage from '../../assets/images/product-placeholder.png';
import TrashIcon from '../../assets/svgs/product/trash-can.svg';

export const ProductListTile = (props: ProductListTileProps) => {
  const { item, shopLink, type } = props;
  // using sitewide context instead of currentpath because it's on home page (product popup)
  const { selectedDispensary, addItemToCart, removeItemFromCart, isMobile } =
    useSiteWideContext();
  const [loading, setLoading] = useState(false);
  const [imgLoadingErr, setImgLoadingErr] = useState(false);
  const pathname = usePathname();

  const image = item.product.images[0]?.url || '';

  const updateQuantity = async (qty: 1 | -1) => {
    if (
      type === 'cart' &&
      props.variant &&
      props.setButtonLoading &&
      addItemToCart &&
      selectedDispensary
    ) {
      setLoading(true);
      props.setButtonLoading(true);
      addItemToCart({
        dispensaryUniqueId: selectedDispensary.uid,
        retailerId: selectedDispensary.retailerId,
        product: item.product,
        quantity: qty,
        variant: props.variant,
        analyticsContext: {
          pathname
        },
        cb: () => {
          setLoading(false);
          props.setButtonLoading(false);
        }
      });
    }
  };

  const priceElement = () => {
    if (
      (type !== 'cart' && type !== 'popup' && type !== 'order') ||
      !props.variant
    )
      return null;
    if (type === 'popup') {
      return (
        <div className="price-info text-right">
          <PriceSection
            variant={props.variant}
            type="list-tile"
            isMobile={isMobile}
          />
        </div>
      );
    }

    return (
      <div className="cart-price-info text-right text-center">
        <PriceSection variant={props.variant} type="cart" isMobile={isMobile} />
      </div>
    );
  };

  const deleteItem = async () => {
    if (type === 'cart' && selectedDispensary) {
      setLoading(true);
      props.setButtonLoading(true);
      await removeItemFromCart.mutateAsync({
        cartItem: item,
        cb: () => {
          setLoading(false);
          props.setButtonLoading(false);
        },
        dispensaryUniqueId: selectedDispensary.uid,
        retailerId: selectedDispensary.retailerId
      });
    }
  };

  const renderPriceSide = () => {
    switch (type) {
      case 'cart': {
        return (
          <div className="qd d-flex flex-column align-items-center gap-8">
            <QuantitySelect
              type="cart"
              value={item.quantity}
              max={(props.variant && (props.variant.quantity as number)) || 0}
              onDecrement={() => updateQuantity && updateQuantity(-1)}
              onIncrement={() => updateQuantity && updateQuantity(1)}
            />
            {!isMobile ? (
              <a
                data-test="delete"
                className="delete body-sm font-bold pointer underline"
                onClick={() => deleteItem && deleteItem()}>
                Remove
              </a>
            ) : null}
          </div>
        );
      }
      case 'order': {
        return (
          <div className="pv-info">
            <span className="body-l margin-right-10">Qty</span>
            <span className="body-l">{props.item.quantity}</span>
          </div>
        );
      }
      case 'popup':
      default: {
        return null;
      }
    }
  };

  return (
    <div className={`product-list-tile d-flex bg-white padding-16 ${type}`}>
      {loading ? (
        <div className="spinner-icon d-flex align-items-center">
          <div className="loading-spinner" />
        </div>
      ) : (
        <>
          {type !== 'popup' ? (
            <StrainTag strain={item.product.strainType} />
          ) : null}
          <div
            data-order-history={
              (props as ProductListTileOrderProps).isOrderHistory || false
            }
            className="info-container d-flex align-items-center justify-content-between">
            <div className="inbv-info d-flex align-items-center">
              <div className="image-container">
                {type === 'popup' ? (
                  <StrainTag strain={item.product.strainType} />
                ) : null}
                {'isOrderHistory' in props && props.isOrderHistory ? (
                  <div className="body-xl text-decoration-none hover-underline">
                    <Image
                      data-test={image ? 'image' : 'default-image'}
                      src={
                        image && !imgLoadingErr ? image : ProductDefaultImage
                      }
                      alt={
                        image ? item.product.name : 'curaleaf-logo-placeholder'
                      }
                      onError={() => setImgLoadingErr(true)}
                      fill={true}
                      sizes="100%"
                      style={{
                        objectFit: 'contain'
                      }}
                    />
                  </div>
                ) : (
                  <Link
                    className="body-xl text-decoration-none hover-underline"
                    href={ecommerceSanitizeQuery(
                      `${shopLink}/products/${item.product.id}`
                    )}>
                    <Image
                      data-test={image ? 'image' : 'default-image'}
                      src={
                        image && !imgLoadingErr ? image : ProductDefaultImage
                      }
                      alt={
                        image ? item.product.name : 'curaleaf-logo-placeholder'
                      }
                      onError={() => setImgLoadingErr(true)}
                      fill={true}
                      sizes="100%"
                      style={{
                        objectFit: 'contain'
                      }}
                    />
                  </Link>
                )}
              </div>
              <div className="nbv d-flex flex-column justify-content-center">
                {item.product.brand ? (
                  <p
                    data-test="brand"
                    className="product-brand body-l font-medium text-black margin-bottom-0">
                    {item.product.brand.name}
                  </p>
                ) : null}
                {shopLink && item.product.id ? (
                  <Link
                    className="product-name body-l font-bold text-decoration-none text-black"
                    href={ecommerceSanitizeQuery(
                      `${shopLink}/products/${item.product.id}`
                    )}>
                    {item.product.name}
                  </Link>
                ) : (
                  <a className="product-name body-l font-bold text-decoration-none text-black">
                    {item.product.name}
                  </a>
                )}
                <div
                  className="moodi-day-rating-overlay"
                  data-productid={item.product.id}></div>
                <div className="card-description">
                  {props.variant && props.variant.option !== 'N/A' ? (
                    <h6 className="body-sm text-black margin-top-5 margin-bottom-0">
                      {props.item.product.category === 'APPAREL'
                        ? props.variant.option.toUpperCase()
                        : props.variant.option}
                    </h6>
                  ) : (
                    <h6 className="body-sm text-black margin-top-5 margin-bottom-0">
                      {item.product.cardDescription}
                    </h6>
                  )}
                  {isMobile ? (
                    <button className="trash-btn pointer" onClick={deleteItem}>
                      <TrashIcon />
                    </button>
                  ) : null}
                </div>
                {isMobile ? (
                  <>
                    {priceElement()}
                    {renderPriceSide()}
                  </>
                ) : null}
              </div>
            </div>
            {!isMobile ? (
              <div className="right-col d-flex align-items-center">
                <>
                  {renderPriceSide()}
                  {priceElement()}
                </>
              </div>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
};
