import { useEffect, useState } from 'react';

import { CurrentPathDispensary, DatalayerAnalytics } from 'services';
import { CategoryLink } from '../../../hooks/siteWideContext';

import AllProducts from '../../../assets/svgs/category-menu/all-products.svg';
import Flower from '../../../assets/svgs/category-menu/flower.svg';
import Edibles from '../../../assets/svgs/category-menu/edible.svg';
import Vaporizer from '../../../assets/svgs/category-menu/vaporizer.svg';
import PreRolls from '../../../assets/svgs/category-menu/preroll.svg';
import Concentrate from '../../../assets/svgs/category-menu/concentrate.svg';
import Drink from '../../../assets/svgs/category-menu/drinks.svg';
import Tincture from '../../../assets/svgs/category-menu/tincture.svg';
import Accessories from '../../../assets/svgs/category-menu/accessories.svg';
import Deals from '../../../assets/svgs/shared/specials-tag.svg';

import './category-menu.scss';

const renderCategoryIcon = (category: string) => {
  switch (category) {
    case 'FLOWER': {
      return <Flower />;
    }
    case 'EDIBLES': {
      return <Edibles />;
    }
    case 'VAPORIZERS': {
      return <Vaporizer />;
    }
    case 'PRE_ROLLS': {
      return <PreRolls />;
    }
    case 'CONCENTRATES': {
      return <Concentrate />;
    }
    case 'DRINKS': {
      // case 'LIQUIDS': {
      return <Drink />;
    }
    case 'TINCTURES': {
      return <Tincture />;
    }
    case 'ACCESSORIES': {
      return <Accessories />;
    }
    case 'SPECIALS': {
      return <Deals />;
    }
    default: {
      return <AllProducts />;
    }
  }
};

export const CategoryMenu = ({
  currentPathDispensary,
  categories,
  shopLink
}: {
  categories: CategoryLink[];
  currentPathDispensary: CurrentPathDispensary;
  shopLink: string;
}) => {
  const [cats, setCats] = useState<CategoryLink[]>([]);

  useEffect(() => {
    // fixes hydration error. cannot map through props.categories directly
    if (categories.length) {
      setCats(categories);
    }
  }, [categories]);

  return (
    <nav className="category-menu">
      <div>
        <ul id="category-menu-sidebar" className="d-flex flex-column gap-24">
          <ShopLink
            category={{
              link: shopLink,
              title: 'Shop All',
              key: ''
            }}
            currentPathDispensary={currentPathDispensary}
          />
          <ShopLink
            category={{
              link: `${currentPathDispensary.shopLink}/specials`,
              title: 'Specials',
              key: 'SPECIALS'
            }}
            currentPathDispensary={currentPathDispensary}
          />
          {cats.length
            ? cats.map((cat) => (
                <ShopLink
                  category={cat}
                  key={cat.key}
                  currentPathDispensary={currentPathDispensary}
                />
              ))
            : null}
        </ul>
      </div>
    </nav>
  );
};

const ShopLink = ({
  category,
  currentPathDispensary
}: {
  category: Omit<CategoryLink, 'rank'>;
  currentPathDispensary: CurrentPathDispensary;
}) => (
  <li className="cat-link">
    <a
      href={category.link}
      onClick={() => {
        try {
          DatalayerAnalytics.pushCategorySelect({
            click_location: 'sticky category sidebar',
            link_text: category.title,
            link_url: category.link,
            consolidateDispensary: currentPathDispensary
          });
        } catch (err) {
          console.error(err);
        }
      }}
      className="d-flex gap-12 align-items-center">
      <span className="category-icon d-flex align-items-center justify-content-center">
        {renderCategoryIcon(category.key)}
      </span>
      <span className="body-l font-bold text-primary">{category.title}</span>
    </a>
  </li>
);
