'use client';

import Cookies from 'js-cookie';
import {
  Dispatch,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react';
import { usePathname, useRouter } from 'next/navigation';
import axios, { AxiosResponse } from 'axios';
import moment from 'moment';

import {
  AccountMode,
  AuthContextTypes,
  AuthUser,
  Consolidate_Dispensary,
  Consolidate_ProductVariant,
  Curaql_OrderType,
  Curaql_PricingType,
  Curaql,
  DatalayerAnalytics,
  GoogleGeoIp,
  SiteWideDispensary,
  StoreCardDispensary,
  DutchiePlus_PricingType,
  Consolidate_Cart_V2,
  DutchiePlus_Generated_Checkout,
  Consolidate_Cart_Item_V2
} from 'services';
import {
  DutchieAddToCartProps,
  DutchieRemoveFromCartProps,
  DutchieUpdateOrderTypeProps
} from '../useDutchieCart';
import useWindowDimensions from '../useWindowDimensions';
import { ProductListTilePopupProps } from '../../components/ProductListTile';
import { deliveryTypeCheck } from '../../utils/ecommerce';
import { UseMutationResult } from '@tanstack/react-query';
import { optInBoolean } from './helpers';
import { AuthDrawer } from '../../components/Account/Drawer/auth';
import { CookieAcceptanceBanner } from '../../components/CookieAcceptanceBanner';
import { fetchDispoCategories } from '../../utils/fetchDispoCategories';
import { useDutchieCartData } from '../useDutchieCart';

type PopupProduct = {
  product: ProductListTilePopupProps['item']['product'];
  variant: Consolidate_ProductVariant;
  quantity: number;
};

export type CategoryLink = {
  key: string;
  title: string;
  link: string;
};

type CartContextType = {
  addItemToCart: (variables: DutchieAddToCartProps) => void;
  cart: {
    data?: Consolidate_Cart_V2;
    loading: boolean;
    error: Error | null;
    refetch: () => void;
  };
  clearCart?: (cb?: () => void) => void;
  quantity: number;
  removeItemFromCart: UseMutationResult<
    { removeItem: DutchiePlus_Generated_Checkout },
    Error,
    DutchieRemoveFromCartProps,
    unknown
  >;
  updateCartOrderType?: UseMutationResult<
    unknown,
    any,
    DutchieUpdateOrderTypeProps,
    unknown
  >;
};

export type SiteWideWrapperProps = {
  clearSelectedDispensaryIDForKiosk: () => void;
  userMenuType: Consolidate_Dispensary['menuTypes'][number];
  setUserMenuType: Dispatch<Consolidate_Dispensary['menuTypes'][number]>;
  userOrderType: Curaql_OrderType;
  setUserOrderType: Dispatch<Curaql_OrderType>;
  selectedDispensary?: SiteWideDispensary | null;
  selectedDispensaryID: string;
  setSelectedDispensary: Dispatch<string>;
  setSelectedDispensaryID: Dispatch<string>;
  selectedDispensaryLoading: boolean;
  isMobile: boolean;
  isTablet: boolean;
  width?: number;
  categories: {
    data: CategoryLink[];
    loading: boolean;
  };
  popup: {
    show: boolean;
    product: PopupProduct | null;
    setProduct: Dispatch<PopupProduct>;
    setShow: (bool: boolean) => void;
  };
  websiteUrl: string;
} & AuthContextTypes &
  CartContextType;

export const SiteWideContext = createContext<SiteWideWrapperProps>(
  {} as SiteWideWrapperProps
);

interface SiteWideContextProviderProps {
  children: ReactNode;
  contextProps: {
    isPreview: boolean;
  };
}

const CART_META_KEY = Curaql.CART_META_KEY;

export const SiteWideContextProvider = ({
  children,
  contextProps
}: SiteWideContextProviderProps) => {
  const pathname = usePathname();
  const websiteUrl =
    typeof window !== 'undefined' ? window.location.origin : '';

  /* ================== AUTH CONTEXT ================== */
  const [user, setUser] = useState<AuthUser>({
    _id: '',
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    birthday: '',
    state: '',
    optIn: false,
    optInSms: false
  });
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [timestamp, setTimestamp] = useState(0);
  const [accountDrawerVisible, setAccountDrawerVisible] = useState(false);
  const [accountDrawerMode, setAccountDrawerMode] =
    useState<AccountMode>('login');
  const [accountDrawerUserEmail, setAccountDrawerUserEmail] =
    useState<string>();
  const [userLoading, setUserLoading] = useState(true);
  const tokenCookie = Cookies.get('curaleafAccountId');

  const clearUser = () => {
    setUser({
      _id: '',
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      birthday: '',
      state: '',
      optIn: false,
      optInSms: false
    });
    setIsLoggedIn(false);
    Cookies.remove('curaleafAccountId', {
      domain: process.env.COOKIE_DOMAIN ? process.env.COOKIE_DOMAIN : undefined
    });
    Cookies.remove('category_preferences', {
      domain: process.env.COOKIE_DOMAIN ? process.env.COOKIE_DOMAIN : undefined
    });
    Cookies.remove('categoryViewpreferences', {
      domain: process.env.COOKIE_DOMAIN ? process.env.COOKIE_DOMAIN : undefined
    });
    if (typeof window !== 'undefined') {
      window.curaleaf_id = '';
    }
  };

  const checkUser = async () => {
    setUserLoading(true);
    return await axios({
      method: 'GET',
      withCredentials: true,
      url: `${process.env.AUTH_URL}/api/auth/v1/isAuthenticated`,
      validateStatus: (status) =>
        (status >= 200 && status < 300) || status === 401
    }).then(async (res) => {
      if (res.data == 'Authenticated') {
        await axios({
          method: 'GET',
          withCredentials: true,
          url: `${process.env.AUTH_URL}/api/auth/v1/user`
        })
          .then(async (res: AxiosResponse) => {
            if (res.data) {
              const user = res.data;
              setUser({
                ...user,
                optIn: user.mainSubscriptionStatus
                  ? user.mainSubscriptionStatus === 'opted_in'
                  : user.marketingOptIn
              });
              setIsLoggedIn(true);
              setTimestamp(moment().valueOf() + 5 * 60000);
              // This is for customer matching
              if (typeof window !== 'undefined') {
                window.curaleaf_id = user.curaleafId;
              }
            }
          })
          .catch((err) => {
            clearUser();
            console.error(err);
          })
          .finally(() => {
            setUserLoading(false);
          });
      } else {
        clearUser();
        setUserLoading(false);
      }
    });
  };

  useEffect(() => {
    if (process.env.IS_KIOSK !== 'true') {
      if (tokenCookie && moment().valueOf() > timestamp) {
        checkUser();
      } else {
        setUserLoading(false);
      }
    }
  }, [pathname]);

  useEffect(() => {
    if (process.env.IS_KIOSK !== 'true') {
      if (tokenCookie) {
        checkUser();
      } else {
        setUserLoading(false);
      }
    }
  }, [tokenCookie]);

  const intitiateAuthHandoff = async (token: string) => {
    const queryParams = new URLSearchParams(location.search);
    const orderType = queryParams.get('orderType');
    return await axios({
      method: 'GET',
      url: `${process.env.AUTH_URL}/api/auth/v1/consume-handoff-token`,
      validateStatus: (status) =>
        (status >= 200 && status < 300) || status === 401,
      headers: {
        'x-cura-handoff': token
      },
      withCredentials: true
    }).then(async (res) => {
      Cookies.set(
        'curaleafAccountId',
        res.data.curaleafAccountId,
        process.env.COOKIE_DOMAIN
          ? {
              domain: process.env.COOKIE_DOMAIN
            }
          : undefined
      );
      // sets local storage to prevent popups
      process.env.CONTENTSTACK_ENVIRONMENT?.includes('ct')
        ? window.sessionStorage.getItem('confirmed21OrOlder')
        : window.localStorage.getItem('confirmed21OrOlder');
      window.localStorage.setItem(
        'promptedForMailingList',
        JSON.stringify(Date.now())
      );

      updateUserOrderTypeLocalStorage(orderType as Curaql_OrderType);

      const user = await axios.get(`${process.env.AUTH_URL}/api/auth/v1/user`, {
        withCredentials: true
      });
      if (user) {
        // Note: This string is split downstream for the id value
        const localCart = Cookies.get(CART_META_KEY);
        if (
          (localCart &&
            JSON.parse(localCart).items &&
            user.data.cart?.items?.length &&
            parseInt(JSON.parse(localCart).updatedAt) <
              parseInt(user.data.cart.updatedAt)) ||
          (user.data.cart && (!localCart || !JSON.parse(localCart).items))
        ) {
          Cookies.set(
            CART_META_KEY,
            JSON.stringify({
              checkoutId: user.data.cart.id,
              dispensaryUniqueId: user.data.cart.dispensaryUniqueId,
              menuType: user.data.cart.pricingType,
              redirectUrl: user.data.cart.redirectUrl,
              updatedAt: user.data.cart.updatedAt
            })
          );
        }

        // TODO: refactor to accomodate form after braze integration
        setUser({
          _id: user.data._id,
          email: user.data.email,
          firstName: user.data.firstName,
          lastName: user.data.lastName,
          phone: user.data.phone,
          birthday: user.data.birthday,
          state: user.data.state,
          optIn: user.data.mainSubscriptionStatus
            ? optInBoolean(user.data.mainSubscriptionStatus)
            : user.data.marketingOptIn,
          optInSms: !!user.data.smsSubscriptionStatus
        });
      }
    });
  };

  /* ================== SITE WIDE CONTEXT ================== */
  const { width } = useWindowDimensions();
  const { refresh } = useRouter();

  /* ----- USER ORDER TYPE ----- */
  const [userOrderType, setUserOrderType] = useState<Curaql_OrderType>(
    typeof window !== 'undefined' && window.localStorage.getItem('orderType')
      ? (window.localStorage.getItem('orderType') as Curaql_OrderType)
      : 'PICKUP'
  );
  const updateUserOrderTypeLocalStorage = (type: Curaql_OrderType) => {
    // couldn't rely on just useState because we need to set local storage
    window.localStorage.setItem('orderType', type);
    setUserOrderType(type);
  };

  /* ----- SELECTED DISPENSARY/ID ----- */
  const [selectedDispensary, updateSelectedDispensary] = useState<
    SiteWideDispensary | undefined | null
  >();
  const [selectedDispensaryLoading, setDispensaryLoading] =
    useState<boolean>(true);

  const storageSelectedDispensaryID =
    typeof window !== 'undefined'
      ? window.localStorage.getItem('selectedDispensaryId')
      : '';
  const selectedDispensaryID = storageSelectedDispensaryID
    ? storageSelectedDispensaryID
    : '';

  const setSelectedDispensaryID = (uid: string) => {
    window.localStorage.setItem('selectedDispensaryId', uid);
  };

  const clearSelectedDispensaryID = () => {
    window.localStorage.removeItem('selectedDispensaryId');
    updateSelectedDispensary(null);
  };

  const getDispoFromIPGeo = async () => {
    if (process.env.ENABLE_IP_GEO !== 'true') {
      return;
    }
    await GoogleGeoIp.getGoogleCoords().then(async (res) => {
      const { location } = res;
      if (location && location.lat && location.lng) {
        const googleCoords = {
          latitude: location.lat,
          longitude: location.lng
        };
        await fetch(
          `${websiteUrl}/api/dispensaries/store-drawer?forDefaultStore=true&coordinates=${JSON.stringify(
            googleCoords
          )}&isPreview=${contextProps.isPreview}`,
          { next: { revalidate: process.env.DISABLE_REDIS ? 1 : 300 } }
        )
          .then((res) => res.json())
          .then(
            async ({ data }: { data: Pick<StoreCardDispensary, 'uid'> }) => {
              if (data?.uid) {
                setSelectedDispensary(data.uid);
                setDispensaryLoading(false);
              } else {
                return;
              }
            }
          )
          .catch((error) => {
            DatalayerAnalytics.pushErrorEvent({
              category: 'api',
              location: 'getDispoFromIPGeo',
              description:
                (error as string) ||
                'Error getting dispensary using IP in sitewide context.',
              consolidateDispensary: selectedDispensary as SiteWideDispensary
            });
          });
      } else {
        return;
      }
    });
  };

  useEffect(() => {
    if (
      (selectedDispensaryID && !selectedDispensary) ||
      (selectedDispensaryID &&
        selectedDispensary &&
        selectedDispensaryID !== selectedDispensary.uid)
    ) {
      // get selected dispensary if there is a UID in local storage
      getSelectedDispensaryInfo(selectedDispensaryID);
    } else if (process.env.IS_KIOSK !== 'true' && !selectedDispensaryID) {
      setDispensaryLoading(true);
      // get selected dispensary based on geolocation
      navigator.permissions?.query({ name: 'geolocation' }).then((result) => {
        if (result.state === 'granted') {
          // fetch dispensary based on geolocation coordinates
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const coords = {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
              };
              if (coords.latitude && coords.longitude) {
                fetch(
                  `${websiteUrl}/api/dispensaries/store-drawer?forDefaultStore=true&coordinates=${JSON.stringify(
                    coords
                  )}&isPreview=${contextProps.isPreview}`,
                  { next: { revalidate: process.env.DISABLE_REDIS ? 1 : 300 } }
                )
                  .then((res) => res.json())
                  .then(
                    ({ data }: { data: Pick<StoreCardDispensary, 'uid'> }) => {
                      if (data?.uid) {
                        setSelectedDispensary(data.uid);
                        setDispensaryLoading(false);
                      } else {
                        getDispoFromIPGeo();
                      }
                    }
                  )
                  .catch((error) => {
                    DatalayerAnalytics.pushErrorEvent({
                      category: 'api',
                      location: 'packages/ui/hooks/siteWideContext/index.tsx',
                      description:
                        (error as string) ||
                        'Error fetching selected dispensary in Sitewide Context useEffect',
                      consolidateDispensary:
                        selectedDispensary as SiteWideDispensary
                    });
                  });
              } else {
                getDispoFromIPGeo();
              }
            },
            getDispoFromIPGeo,
            {
              timeout: 5000
            }
          );
        } else {
          getDispoFromIPGeo();
        }
      });
    }
    setDispensaryLoading(false);
  }, [selectedDispensaryID, selectedDispensary]);

  const getSelectedDispensaryInfo = async (uid: string) => {
    if (!uid) return;

    setDispensaryLoading(true);
    try {
      const response = await fetch(`${websiteUrl}/api/dispensaries/${uid}`, {
        next: { revalidate: process.env.DISABLE_REDIS ? 1 : 300 }
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch dispensary: ${response.statusText}`);
      }

      const { data }: { data: SiteWideDispensary } = await response.json();

      // Validate dispensary based on environment
      const isStateSite = /^(ct|nd|ut)/.test(
        process.env.CONTENTSTACK_ENVIRONMENT || ''
      );

      const isValidDispensary = isStateSite
        ? new RegExp(`^${data.location.state.abbreviation}-`, 'i').test(
            process.env.CONTENTSTACK_ENVIRONMENT || ''
          )
        : data &&
          (!/^(CT|ND|UT)$/i.test(data.location?.state.abbreviation) ||
            process.env.IS_KIOSK === 'true');

      if (isValidDispensary) {
        updateSelectedDispensary(data);
        if (categories?.dispensaryId !== uid) {
          await getCategories(data);
        }
      } else {
        clearSelectedDispensaryID();
      }
    } catch (error) {
      const err =
        error instanceof Error
          ? error
          : new Error('Failed to fetch dispensary info');
      DatalayerAnalytics.pushErrorEvent({
        category: 'api',
        location: 'getSelectedDispensaryInfo',
        description: err.message,
        consolidateDispensary: selectedDispensary as SiteWideDispensary
      });
      clearSelectedDispensaryID();
    } finally {
      setDispensaryLoading(false);
    }
  };

  const setSelectedDispensary = (uid: string) => {
    // Don't proceed if we're already loading this dispensary
    if (selectedDispensary?.uid === uid && !selectedDispensaryLoading) return;

    // Update the ID first
    setSelectedDispensaryID(uid);

    // Fetch dispensary info if:
    // 1. We don't have a dispensary yet, or
    // 2. The new ID is different from our current dispensary
    if (!selectedDispensary || selectedDispensary.uid !== uid) {
      getSelectedDispensaryInfo(uid);
    }
  };

  /* -----  USER MENU/PRICING TYPE ----- */
  const [userMenuType, setUserMenuTypeState] =
    useState<DutchiePlus_PricingType>(() => {
      if (typeof window === 'undefined') return 'RECREATIONAL';
      const stored = window.localStorage.getItem('pricingType');
      return stored === 'RECREATIONAL' || stored === 'MEDICAL'
        ? stored
        : 'RECREATIONAL';
    });

  const setUserMenuType = (type: Curaql_PricingType) => {
    window.localStorage.setItem('pricingType', type);
    setUserMenuTypeState(type as DutchiePlus_PricingType);

    // Check delivery availability with the new menu type
    if (userOrderType === 'DELIVERY' && selectedDispensary) {
      const hasDelivery = deliveryTypeCheck(
        selectedDispensary.orderTypes,
        type
      );
      if (!hasDelivery) {
        setUserOrderType('PICKUP');
      }
    }

    refresh();
  };

  /* ----- CATEGORIES ----- */
  const [categories, setCategories] = useState<
    | {
        categories: CategoryLink[];
        dispensaryId: string;
      }
    | undefined
  >(undefined);
  const [categoriesLoading, setCategoriesLoading] = useState(false);

  const getCategories = async (selectedDispensary: SiteWideDispensary) => {
    // Don't fetch if we already have categories for this dispensary
    if (categories?.dispensaryId === selectedDispensary.uid) return;

    setCategoriesLoading(true);

    try {
      // Determine the menu type to use
      const menuType =
        selectedDispensary.menuTypes.length === 2
          ? userMenuType
          : selectedDispensary.menuTypes[0] || 'RECREATIONAL';

      await fetchDispoCategories(
        selectedDispensary,
        menuType,
        contextProps.isPreview,
        setCategoriesLoading,
        setCategories
      );
    } catch (error) {
      const err =
        error instanceof Error
          ? error
          : new Error('Failed to fetch categories');
      DatalayerAnalytics.pushErrorEvent({
        category: 'api',
        location: 'getCategories',
        description: err.message,
        consolidateDispensary: selectedDispensary
      });
    }
  };

  // Effect to update categories when menu type or dispensary changes
  useEffect(() => {
    if (selectedDispensary && !selectedDispensaryLoading) {
      getCategories(selectedDispensary);
    }
  }, [userMenuType, selectedDispensary?.uid, selectedDispensaryLoading]);

  /* ----- PRODUCT POPUP ----- */
  const [showPopup, togglePopup] = useState<boolean>(false);
  const [popupProduct, setPopupProduct] = useState<PopupProduct | null>(null);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (showPopup) {
      if (pathname.includes('/cart')) {
        togglePopup(false);
      } else {
        timer = setTimeout(() => {
          togglePopup(false);
          setPopupProduct(null);
        }, 7000);
      }
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [showPopup, pathname]);

  const setShowPopup = (bool: boolean) => {
    if (!pathname.includes('/cart')) {
      togglePopup(bool);
    }
  };

  /* ------ DUTCHIE CART ------ */
  const [cartQuantity, setCartQuantity] = useState(0);

  const calculateCartQuantity = (
    items: Consolidate_Cart_Item_V2[] | undefined
  ) => {
    if (!items?.length) return 0;
    return items.reduce((total, item) => total + item.quantity, 0);
  };

  const {
    addItemToCart: addItemToCart,
    cart: dutchieCart,
    clearCart,
    removeItemFromCart: removeItemFromCart,
    updateCartOrderType
  } = useDutchieCartData({
    selectedDispensary,
    selectedDispensaryID,
    selectedDispensaryLoading,
    setPopupProduct,
    setShowPopup,
    updateUserOrderTypeLocalStorage,
    user,
    userMenuType
  });

  /* ----- CURAQL CART COOKIES ----- */
  // Effect to validate cart against selected dispensary
  useEffect(() => {
    if (!selectedDispensary || selectedDispensaryLoading) return;

    const cartCookie = Cookies.get(CART_META_KEY);
    if (!cartCookie) return;

    try {
      const parsedCartCookie = JSON.parse(cartCookie);
      const cartDispo = parsedCartCookie.dispensaryUniqueId;

      // Clear cart if it belongs to a different dispensary
      if (cartDispo !== selectedDispensary.uid) {
        console.log('Cart dispensary mismatch detected, clearing cart');
        Cookies.remove(CART_META_KEY);
        clearCart?.();
      }
    } catch (error) {
      console.error('Invalid cart cookie format:', error);
      Cookies.remove(CART_META_KEY);
      clearCart?.();
    }
  }, [selectedDispensary?.uid, selectedDispensaryLoading, pathname, clearCart]);

  /* ------ CART QUANTITY ------ */
  useEffect(() => {
    const newQuantity = calculateCartQuantity(dutchieCart?.data?.items);
    if (newQuantity !== cartQuantity) {
      setCartQuantity(newQuantity);
    }
  }, [dutchieCart?.data?.items, cartQuantity]);

  /* ------------------------------------------------------ */
  return (
    <SiteWideContext.Provider
      value={{
        /* AUTH */
        accountDrawerMode,
        accountDrawerUserEmail,
        accountDrawerVisible,
        clearUser,
        intitiateAuthHandoff,
        isLoggedIn,
        loading: userLoading,
        setAccountDrawerMode,
        setAccountDrawerUserEmail,
        setAccountDrawerVisible,
        setUser,
        user,
        /* SITEWIDE */
        categories: {
          data: categories?.categories ?? [],
          loading: selectedDispensaryLoading || categoriesLoading // categories is only called after fetching selected dispensary
        },
        clearSelectedDispensaryIDForKiosk: clearSelectedDispensaryID,
        isMobile: !!(width && width <= 767),
        isTablet: !!(width && width <= 1024),
        width,
        popup: {
          product: popupProduct,
          setProduct: setPopupProduct,
          setShow: setShowPopup,
          show: showPopup
        },
        selectedDispensary,
        selectedDispensaryID,
        selectedDispensaryLoading,
        setSelectedDispensary,
        setSelectedDispensaryID,
        setUserMenuType,
        setUserOrderType: updateUserOrderTypeLocalStorage,
        userMenuType,
        userOrderType,
        websiteUrl,
        /* DUTCHIE CART */
        cart: {
          loading: dutchieCart.loading,
          error: dutchieCart.error,
          data: dutchieCart.data || undefined,
          refetch: dutchieCart.refetch
        },
        addItemToCart,
        clearCart,
        quantity: cartQuantity,
        removeItemFromCart,
        updateCartOrderType
      }}>
      {children}
      {process.env.IS_KIOSK !== 'true' ? (
        <>
          <AuthDrawer
            setVisibility={setAccountDrawerVisible}
            visible={accountDrawerVisible}
          />
          <CookieAcceptanceBanner />
        </>
      ) : null}
    </SiteWideContext.Provider>
  );
};

// Use this in child components
export const useSiteWideContext = () => useContext(SiteWideContext);
