import { LoadingSkeleton } from '../Loader/Skeleton';
import { OrderSummary } from '../OrderSummary';
import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { ProductListTile } from '../ProductListTile';

export const Loader = (): JSX.Element | null => {
  const orderTiles = new Array(2).fill(undefined);
  return (
    <PackageComponentWrapper>
      <div className="order-confirmation container-lr d-flex flex-column gap-24 oc-loader">
        <div className="order-content">
          <h4 className="margin-bottom-10">
            <LoadingSkeleton width="40%" />
          </h4>
          <h3 className="margin-bottom-10">
            <LoadingSkeleton width="70%" />
          </h3>
          <p>
            <LoadingSkeleton width="20%" />
          </p>
          <p>
            <LoadingSkeleton width="100%" />
            <LoadingSkeleton width="40%" />
          </p>
          <p>
            <LoadingSkeleton width="45%" />
          </p>
          <p>
            <LoadingSkeleton width="45%" />
          </p>
        </div>
        <div className="order-summary d-flex gap-24 margin-bottom-32">
          <div className="order-tile-wrapper d-flex gap-24 flex-column">
            {orderTiles.map((_, i) => (
              <ProductListTile
                key={i}
                props={{
                  isLoading: true
                }}
              />
            ))}
          </div>
          <div className="order-total-wrapper">
            <div className="order-total bg-secondary-light padding-24 d-flex flex-column gap-16">
              <OrderSummary props={{ isLoading: true }} />
            </div>
          </div>
        </div>
      </div>
    </PackageComponentWrapper>
  );
};
