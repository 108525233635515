'use client';

import { Dispatch } from 'react';
import classNames from 'classnames';

import { capitalize, StoreCardDispensary } from 'services';
import { CTAButton, CTAButtonProps } from '../CTAButton';
import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { useSiteWideContext } from '../../hooks/siteWideContext';
import { deliveryTypeCheck } from '../../utils/ecommerce';
import { ErrorBoundary } from '../../utils/nullErrorBoundary';

import SelectedIcon from '../../assets/svgs/shared/icon-selected.svg';

import './store-card.scss';

export type StoreCardProps = {
  dispensary: Omit<StoreCardDispensary, 'deliveryOnly' | 'openTimes' | 'type'>;
  drawerType?: StoreCardDispensary['orderTypes'][number];
  openStoreDrawer?: Dispatch<boolean>;
  type: 'drawer' | 'location' | 'overview' | 'kiosk';
};

export const StoreCard = ({
  dispensary,
  drawerType,
  openStoreDrawer,
  type
}: StoreCardProps): JSX.Element | null => {
  const {
    clearCart,
    isMobile,
    selectedDispensaryID,
    setSelectedDispensaryID,
    setUserOrderType,
    userMenuType,
    setUserMenuType
  } = useSiteWideContext();

  if (!dispensary) return null;

  const handleShopClick = () => {
    if (openStoreDrawer) openStoreDrawer(false);
    if (
      drawerType &&
      drawerType === 'DELIVERY' &&
      dispensary.orderTypes?.length
    ) {
      const hasDelivery = deliveryTypeCheck(
        dispensary.orderTypes,
        userMenuType
      );
      setUserOrderType(hasDelivery ? drawerType : 'PICKUP');
    } else {
      setUserOrderType('PICKUP');
    }
    if (selectedDispensaryID !== dispensary.uid && clearCart) {
      clearCart();
      // Temp fix for older devices
      setSelectedDispensaryID(dispensary.uid);
    }
    if (
      !dispensary.menuTypes.includes(userMenuType) &&
      dispensary.menuTypes[0]
    ) {
      setUserMenuType(dispensary.menuTypes[0]);
    }
  };

  const handleDetailClick = () => {
    if (openStoreDrawer) openStoreDrawer(false);
  };

  const cardTags = () => {
    const tags: string[] = [];
    dispensary.menuTypes.map((mt) => {
      if (mt === 'RECREATIONAL') {
        tags.push('Adult-Use');
      } else {
        tags.push(capitalize(mt));
      }
    });
    dispensary.orderTypes.some((ot) => {
      if (ot.includes('DELIVERY')) {
        tags.push(
          ot.includes('_')
            ? ot.replace(
                /_(.+)/,
                (_, match) =>
                  `-${match.charAt(0).toUpperCase()}${match
                    .slice(1)
                    .toLowerCase()}`
              )
            : capitalize(ot)
        );
      }
    });

    if (tags.length) {
      return tags.map((tag, i) => {
        const delivery = tag.toLowerCase().includes('delivery');
        return (
          <span data-delivery-tag={delivery} key={i} className="tag body-sm">
            {tag}
          </span>
        );
      });
    }
    return null;
  };

  const isSelectedDispensary =
    type === 'drawer' && dispensary.uid === selectedDispensaryID;
  const prerelease = dispensary.isPrerelease;
  let shopBtn: CTAButtonProps = {
    callBackFunc: handleShopClick,
    url: dispensary.shopLink,
    text: 'Shop',
    variant: 'primary'
  };
  if (dispensary.externalUrl) {
    shopBtn = {
      text: 'Shop',
      url: dispensary.externalUrl,
      variant: 'primary'
    };
  }
  if (prerelease) {
    shopBtn = {
      allowEmptyUrl: true,
      disabled: true,
      text: 'Coming Soon'
    };
  }

  const renderTodaysHours = (todaysHours: string, extraTitle?: string) => {
    const title = `Today's ${extraTitle || ''} Hours:`;
    return (
      <span className="body-m font-bold text-primary">
        {title}
        {isMobile && todaysHours.includes(', ') ? <br /> : null}
        <span className="body-m text-black margin-left-5">{todaysHours}</span>
      </span>
    );
  };

  return (
    <PackageComponentWrapper minWidth={false}>
      <ErrorBoundary component="store-drawer-card">
        <div
          data-selected={isSelectedDispensary}
          aria-label={`Shop at ${dispensary.friendlyName}`}
          tabIndex={0}
          className="store-card d-flex flex-column bg-white">
          {isSelectedDispensary ? (
            <div className="selected-icon">
              <SelectedIcon />
            </div>
          ) : null}
          <div className="d-flex justify-content-between align-items-end">
            <h3 className="body-xl text-primary margin-bottom-0">
              {dispensary.friendlyName}
            </h3>
            {dispensary.location.distance ? (
              <span className="body-m text-black">
                {dispensary.location.distance} mi
              </span>
            ) : null}
          </div>
          <div className="store-info d-flex flex-column">
            <div className="todays-hours">
              {dispensary.todaysHours && !dispensary.timesets?.length
                ? renderTodaysHours(dispensary.todaysHours)
                : dispensary.timesets?.length
                  ? dispensary.timesets.map((set) => {
                      if (!set.todaysHours) return null;
                      return (
                        <div key={set.title}>
                          {renderTodaysHours(set.todaysHours, set.title)}
                        </div>
                      );
                    })
                  : null}
            </div>
            <div className="tags d-flex body-sm">{cardTags()}</div>
            <div className="d-flex justify-content-between align-items-end">
              <address className="body-m address text-black">
                {dispensary.location.address}
                <br />
                {`${dispensary.location.city}, ${dispensary.location.state.abbreviation} ${dispensary.location.zipcode}`}
              </address>
              {dispensary.splitExternalUrl ? (
                <a
                  href={`/dispensary/${dispensary.location.state.slug}/${dispensary.slug}`}
                  className="d-flex body-m underline pointer"
                  tabIndex={0}
                  role="button"
                  onClick={handleDetailClick}>
                  View Details
                </a>
              ) : null}
            </div>
          </div>
          <div
            className={classNames('store-card-buttons ', {
              'button-group': !prerelease,
              'split-external-store': dispensary.splitExternalUrl
            })}>
            {dispensary.splitExternalUrl ? (
              <>
                {['recreational', 'medical'].map((mt) => {
                  const text =
                    mt === 'recreational' ? 'Shop Adult-Use' : 'Shop Medical';
                  return (
                    <CTAButton
                      key={mt}
                      fullWidth={true}
                      fullWidthMobile={true}
                      text={text}
                      url={`${dispensary.externalUrl}?saletype=${mt}`}
                      variant="primary"
                    />
                  );
                })}
              </>
            ) : (
              <>
                {type === 'overview' ? null : (
                  <CTAButton
                    fullWidth={true}
                    fullWidthMobile={true}
                    {...{ ...shopBtn }}
                  />
                )}
                {prerelease || type === 'kiosk' ? null : (
                  <CTAButton
                    fullWidth={true}
                    text="Details"
                    fullWidthMobile={true}
                    callBackFunc={handleDetailClick}
                    url={`/dispensary/${dispensary.location.state.slug}/${dispensary.slug}`}
                    variant="secondary"
                  />
                )}
              </>
            )}
          </div>
        </div>
      </ErrorBoundary>
    </PackageComponentWrapper>
  );
};
