import { EcomOrderConfirmationProps } from './index';
import { useSiteWideContext } from '../../hooks/siteWideContext';
import { useDispensaryPathContext } from '../../hooks/dispensaryPathContextProvider';
import { Markdown } from '../Markdown';
import { OrderSummary } from '../OrderSummary';
import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { ProductListTile } from '../ProductListTile';

import CuraleafLogo from '../../assets/svgs/header/full-logo-teal-ecom-header.svg';
import StoreIcon from '../../assets/svgs/shared/icon-store.svg';

export const OrderConfirmation = ({
  order
}: EcomOrderConfirmationProps): JSX.Element | null => {
  const { userMenuType } = useSiteWideContext();
  const { currentPathDispensary } = useDispensaryPathContext();
  if (!currentPathDispensary || !order) return null;

  const documents =
    userMenuType === 'MEDICAL'
      ? 'drivers license and medical card'
      : 'drivers license';
  const confirmation = order.confirmationText;
  const content = confirmation
    .replace('[ORDERNUMBER]', order.orderNumber)
    .replace('[DISPENSARYNAME]', currentPathDispensary.friendlyName)
    .replace('[DOCUMENTS]', documents);

  const totalOrderItem = order.items.reduce(
    (total, item) => total + item.quantity,
    0
  );

  return (
    <PackageComponentWrapper>
      <div className="order-confirmation container-lr d-flex flex-column gap-24">
        <Markdown additionalClass="order-content" content={content} />
        <h6 className="title-l font-regular text-primary margin-bottom-0">
          {totalOrderItem} {totalOrderItem > 1 ? 'Items' : 'Item'}
        </h6>
        <div className="order-summary d-flex gap-24 margin-bottom-32">
          <div className="order-tile-wrapper d-flex gap-24 flex-column">
            {order.items.map((item) => {
              const variant = item.product.variants.find(
                (v) => v.option === item.option
              );
              if (!variant) return null;
              return (
                <ProductListTile
                  key={item.product.id}
                  props={{
                    item,
                    isLoading: false,
                    shopLink: currentPathDispensary.shopLink,
                    variant,
                    type: 'order'
                  }}
                />
              );
            })}
          </div>
          <div className="order-total-wrapper">
            <div className="order-total bg-secondary-light padding-24 d-flex flex-column gap-16">
              <CuraleafLogo />
              {process.env.IS_KIOSK !== 'true' ? (
                <div className="d-flex align-items-start gap-8 oc-header">
                  <StoreIcon />
                  <span className="body-l font-bold">
                    Picking up at {currentPathDispensary.friendlyName}
                  </span>
                </div>
              ) : null}
              <div className="hairline" />
              <OrderSummary
                props={{
                  isLoading: false,
                  pricing: {
                    discounts: order.discounts,
                    subtotal: order.subtotal,
                    taxes: order.tax,
                    total: order.total,
                    fees: order.fees / 100
                  },
                  type: 'order'
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </PackageComponentWrapper>
  );
};
