'use client';

import Link from 'next/link';
import classNames from 'classnames';
import { useRouter } from 'next/navigation';

import { useSiteWideContext } from '../../hooks/siteWideContext';
import { ProductPopup } from '../ProductPopup';
import { Searchbar } from './Search';
import { PackageComponentWrapper } from '../PackageComponentWrapper';
import { ecommerceSanitizeQuery } from '../../utils/url';

import CuraleafLogo from '../../assets/svgs/header/full-logo-teal.svg';
import SmCartIcon from '../../assets/svgs/header/cart-sm.svg';

const Cart = (): JSX.Element | null => {
  const { selectedDispensary, quantity } = useSiteWideContext();

  return (
    <div className={classNames('user-cart d-flex align-items-center')}>
      {selectedDispensary?.shopLink ? (
        <Link
          tabIndex={0}
          className="icon-link"
          href={ecommerceSanitizeQuery(`${selectedDispensary.shopLink}/cart`)}>
          <SmCartIcon />
          {quantity > 0 ? (
            <span className="quantity d-flex align-items-center justify-content-center body-sm text-white bg-primary">
              {quantity}
            </span>
          ) : null}
        </Link>
      ) : null}
    </div>
  );
};

export const KioskHeaderNavigation = (): JSX.Element | null => {
  const { popup, selectedDispensary } = useSiteWideContext();
  const { push } = useRouter();

  return (
    <PackageComponentWrapper additionalClass="bg-white header-navigation">
      <header className="kiosk-header bg-white">
        <div className="nav-header d-flex justify-content-between align-items-center">
          {selectedDispensary ? (
            <>
              <div
                tabIndex={0}
                className="margin-right-32 padding-bottom-16 curaleaf-logo-kiosk pointer"
                onClick={() => push(selectedDispensary.shopLink)}>
                <CuraleafLogo />
              </div>
              <div className="kiosk-search-container">
                <Searchbar />
              </div>
              <div
                data-has-hamburger={false}
                className="margin-left-32 user-cart d-flex justify-content-end align-items-center">
                <Cart />
              </div>
            </>
          ) : (
            <div className="margin-bottom-10 curaleaf-logo">
              <CuraleafLogo />
            </div>
          )}
        </div>
      </header>
      {popup.show && popup.product ? (
        <div className="product-popup-container">
          <ProductPopup
            props={{
              item: { product: popup.product.product },
              variant: popup.product.variant
            }}
          />
        </div>
      ) : null}
    </PackageComponentWrapper>
  );
};
